import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiResponse, Guest } from "@core/models";
import { environment } from "@env/environment";
import { map, Observable } from "rxjs";

@Injectable()
export class GuestService {

  private readonly http = inject(HttpClient);

  addGuests(dto: Guest[]): Observable<any> {
    return this.http.post(`${environment.apiUrl}/guests`, dto);
  }

  getGuests(): Observable<Guest[]> {
    return this.http.get<ApiResponse<Guest[]>>(`${environment.apiUrl}/guests`).pipe(
      map(response => response.data)
    );
  }

  updateGuests(dto: Guest[] ): Observable<any> {
    return this.http.put(`${environment.apiUrl}/guests`, dto);
  }
}
